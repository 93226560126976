window.flo_block_listing_3 = el => {
  "use strict";
  // var $el = $(el);
  let b = "flo-block-listing-3";
  let dotb = "." + b;
  // var $b = $el.find(dotb);

  window.floLazy(`${dotb}__featured-image`);

};
