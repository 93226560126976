window.flo_block_gallery_view_2 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-gallery-view-2";
  var dotb = "." + b;
  var $b = $el.find(dotb);

  // masonry
    function do_masonry() {
      $b.masonry({
        columnWidth: '.grid-sizer',
        percentPosition: true
      });
    }

    $(window).on("resize", () => do_masonry())

    // lazyload
    window.image_lazyload(dotb + "__image-thumb")
    $el.find(dotb + "__image-thumb").on("load", e => do_masonry())

  // fancybox
    $el.find(dotb + "__image").fancybox({
      loop: true
    });
};
