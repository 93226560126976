window.flo_block_image_block_2 = el => {
  "use strict";
  let $el = $(el);
  let b = "flo-block-image-block-2";
  let dotb = "." + b;
  // var $b = $el.find(dotb);

  // Remove unnecesary elements from DOM on desktop and mobile
  if ($(dotb).hasClass(b+'__mobile-text-bottom') || $(dotb).hasClass(b+'__mobile-text-top')) {
    if ($(window).width() >= 768) {
      $el.find(dotb+"__mobile-img-wrap").remove();
    }
    if ($(window).width() <= 767) {
      $el.find(dotb+"__image").remove();
    }
  }
};
