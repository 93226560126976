window.flo_footer_area_1_type_d = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-footer-area-1-type-d";
  var dotb = "." + b;

  let footer_images = $(`${dotb}__images`);
  let footer_link = $(`${dotb}__link`);

  if ($(window).width() >= 768) {  
    footer_images.append(footer_link);
  }
}
