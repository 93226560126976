window.flo_block_mosaic_image_links_1 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-block-mosaic-image-links-1";
  var dotb = "." + b;

  //lazyload
  window.floLazy(`${dotb} img`);

}
