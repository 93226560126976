window.flo_wp_content_block = el => {
  "use strict";
  // var $el = $(el);
  var b = "flo-wp-content";
  var dotb = "." + b;
  // var $b = $el.find(dotb);

  if ($(`${dotb}`).hasClass(`${b}--lazy-enabled`)) {
    window.floLazy(`${dotb} img`);
    setTimeout(function(){
      // lazyload Narrative images
         window.floLazy(`.nar-root-loaded img`);
     }, 3000);
  }
};
